import ProjectDetails from "./theme/details";
import Homepage from "./theme/homepage";
import Installation from "./theme/installation";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Page from "./theme/page";
import "./App.css";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { CookiesProvider, useCookies } from "react-cookie";

function App() {
  const [cookies, setCookie, removeCookie] = useCookies([]);
  const [showCookies, setShowCookies] = useState(!cookies["consent"]);

  const [analysisCookie, setAnalysisCookie] = useState(
    cookies["consent_analysis"]
  );

  useEffect(() => {}, [cookies]);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Homepage></Homepage>,
      loader: ({ params }) => {
        return params;
      },
    },
    {
      path: "/:id",
      element: <ProjectDetails></ProjectDetails>,
    },
    // {
    //   path: "/installation",
    //   element: <Installation></Installation>,
    // },
    {
      path: "/p/:id",
      element: <Page></Page>,
      loader: ({ params }) => {
        return params;
      },
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />

      <CookiesProvider defaultSetOptions={{ path: "/" }}>
        {showCookies ? (
          <div
            className="w-100"
            style={{
              backgroundColor: "#043673",
              position: "fixed",
              bottom: "0px",
              padding: "25px",
              zIndex: "100",
            }}
          >
            <div className="container text-white">
              <div>{parse(window["translations"].cookie)}</div>
              <div className="mt-2">
                <label className="me-4">
                  <input
                    type="checkbox"
                    checked
                    value=""
                    disabled
                    className="me-2"
                  />
                  Αναγκαία
                </label>
                <label>
                  <input
                    type="checkbox"
                    className="me-2"
                    onChange={(e) => {
                      setCookie("consent_analysis", e.target.checked);
                      setAnalysisCookie(true);
                    }}
                  />
                  Ανάλυσης
                </label>
              </div>
              <div className="mt-2 text-end">
                <button
                  type="button"
                  className="btn btn-sm ms-2 btn-info"
                  onClick={() => {
                    setCookie("consent_analysis", false);
                    setCookie("consent", true);
                    setShowCookies(false);
                  }}
                >
                  Απόρριψη όλων
                </button>
                <button
                  type="button"
                  className="btn btn-sm ms-2  btn-info"
                  onClick={() => {
                    setCookie("consent", true);
                    setShowCookies(false);
                  }}
                >
                  Αποδοχή επιλεγμένων
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </CookiesProvider>
    </>
  );
}

export default App;
